import React from "react"
import {graphql} from "gatsby"
import Layout from "../layout/layout"
import Post from "../components/PostStyle"
export const query = graphql`
      query($category:String!) {
          allMarkdownRemark(limit: 6, filter: {frontmatter: {categories: {eq: $category}}}, sort: {fields: [frontmatter___date], order: DESC}) {
              totalCount
              edges {
                node {
                  frontmatter {
                    title
                    date(formatString: "YYYY-MM-DD")
                    template
                    thumbnail
                  }
                  fields {
                    slug
                  }
                }
              }
            }
          }`
const Category=(props) =>{
    return(
        <Layout>
            <h1>Category : {props.pageContext.category}</h1>
            <p>Total Posts : {props.data.allMarkdownRemark.totalCount}</p>
            <section class="posts">{props.data.allMarkdownRemark.edges.map((edge,index)=>{
                return(
                  <div className="post" key={index}>
                    <Post postEdges={edge}/>
                  </div>
                )
            })}</section>
            <p>Well, I have lot many things to share. hope, you like what you are going to read now...!</p>
        </Layout>
    )
}
export default Category;